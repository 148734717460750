<template>
  <div class="content activity-detail">
    <div class="title">
      {{activityData.name}}
    </div>
    <div class="base-info">
      <div class="info-item">
        <div class="label">活动地点：</div>
        <div class="value">{{activityData.address}}</div>
      </div>
      <div class="info-item">
        <div class="label">开始时间：</div>
        <div class="value">{{activityData.startDate ? activityData.startDate.slice(0, 10) : ''}}</div>
      </div>
      <div class="info-item">
        <div class="label">结束时间：</div>
        <div class="value">{{activityData.endDate ? activityData.endDate.slice(0, 10) : ''}}</div>
      </div>
      <div class="info-item">
        <div class="label">上传时间：</div>
        <div class="value">{{activityData.uploadTime ? activityData.uploadTime.slice(0, 10) : ''}}</div>
      </div>
      <div class="info-item">
        <div class="label">主办人员：</div>
        <div class="value">{{activityData.hostUserName}}</div>
      </div>
      <div class="info-item">
        <div class="label">参与人：</div>
        <div class="value">{{activityData.participateUserNames.join('、')}}</div>
      </div>
      <div class="info-item">
        <div class="label">编辑人：</div>
        <div class="value">{{activityData.editUserNames.join('、')}}</div>
      </div>
    </div>
    <div class="other-info"></div>
    <div class="content">
      <div class="label">活动简介：</div>
      <div style="text-indent:30px;" v-for="(item, index) in contentList" :key="index">{{item}}</div>
    </div>
    <div class="files">
      <div class="label">活动材料：</div>
      <file-manage
          preview
          :file-data-ids="fileIds"
          :funs="{get: true, upload: false, del: false}">
        </file-manage>
    </div>
  </div>
</template>

<script>
import FileManage from '@/components/base/FileManage'

export default {
  components: {
    FileManage
  },
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      activityData: {},
      fileIds: [],
      formParm: [],
      otherInfo: {},
      contentList: []
    }
  },
  watch: {
    data: {
      handler () {
        let data = JSON.parse(JSON.stringify(this.data))
        this.otherInfo = data.otherInfo ? JSON.parse(data.otherInfo) : {}
        this.fileIds = data.fileIds ? data.fileIds.split(',').map(v => Number(v)) : []
        this.contentList = data.content ? data.content.split('\n') : []
        this.activityData = data
        this.formParm = this.activityData.activityType && this.activityData.activityType.config ? JSON.parse(this.activityData.activityType.config) : []
        console.log(data)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.activity-detail {
  padding: 20px 200px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .title {
    width: 100%;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .label {
    min-width: 80px;
    color: #666;
  }
  .info-item {
    display: flex;
    margin-bottom: 5px;
  }
  .content {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
